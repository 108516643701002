.container {
    .select {
        width: 100%;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
    }
    .button {
        color: #397350;
        font-weight: bold;
    }

    .tabs {
        margin-top: 24px;
    }
}
