.layout {
    min-height: 100vh;

    header {
        background: #fff !important;
        height: 60px !important;
        padding-inline: 30px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            line-height: 50px;
            display: flex;
            justify-content: flex-start;
            align-self: flex-start;

            .logo {
                margin: 10px 5px 10px 0;
                height: 40px;
                width: 40px;
            }

            span {
                font-size: 20px;
                font-weight: 700;
                line-height: 60px;
                color: #548064;
            }
        }
        .options {
            font-size: 15px;
            font-weight: 500;
            line-height: 110px;
            color: #548064;
            align-self: center;
            text-align: center;
        }
    }

    .content {
        flex: 1;
        display: flex;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff !important;
        padding-left: 30px;
        padding-right: 30px;
        color: rgba(139, 141, 151, 0.5);
        .links {
            .link {
                color: rgba(139, 141, 151, 0.5);
            }
        }
        .copyright {
            display: flex;
            justify-content: center;
            margin-top: 8px;

            a {
                color: rgba(139, 141, 151, 0.5);
            }
        }
    }

    .mobileFooter {
        overflow-x: hidden;
        color: white;
        padding: 24px 0;
        justify-content: center;
        background-color: white;
        line-height: 8px;
        font-size: 10px;
        .links {
            display: flex;
            justify-content: center;
            flex-grow: 1;
            .link {
                color: rgba(139, 141, 151, 0.5);
            }
        }
        .copyright {
            display: flex;
            justify-content: center;
            margin-top: 8px;
            flex-grow: 1;

            a {
                color: rgba(139, 141, 151, 0.5);
            }
        }
    }
}
