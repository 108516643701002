.container {
    min-height: 100vh;

    .top {
        width: 100%;
        min-height: 100%;
        top: 0;
        left: 0;
        background-image: url('~/public/images/field.png');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        .header {
            background: transparent;
            height: 60px !important;
            padding-inline: 30px !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .title {
                line-height: 50px;
                display: flex;
                justify-content: flex-start;
                align-self: flex-start;

                .logo {
                    margin: 10px 5px 10px 0;
                    height: 40px;
                    width: 40px;
                }

                span {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 60px;
                    color: #548064;
                }
            }
            .options {
                font-size: 15px;
                font-weight: 500;
                line-height: 110px;
                color: #548064;
                align-self: flex-end;
            }
        }
        .text {
            color: black;
            text-align: center;
            position: relative;
            font-size: 30px;
            font-weight: 500;
            padding-bottom: 400px;
            padding-left: 100px;
            padding-right: 100px;
            h1 {
                color: #467c5a;
                padding-top: 140px;
            }
        }
        .buttons {
            width: 20%;
            height: 15%;
            margin-top: 48px;
            font-size: 20px;
            font-weight: bold;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .benefits {
        background-color: #fff;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        .heading {
            color: black;
            font-size: 40px;
            padding-bottom: 30px;
            padding-top: 30px;
            text-align: left;
        }
        .imageContainer {
            width: 50%;
            padding-bottom: 150px;
            text-align: left;
            .image {
                width: 90%;
                border-radius: 75px;
            }
        }
        .text {
            text-align: left;
            color: #467c5a;
            font-size: 20px;
            vertical-align: middle;

            h2 {
                line-height: 30px;
            }
            .dot {
                height: 80px;
                width: 80px;
                background-color: #467c5a;
                border-radius: 50%;
                color: #fff;
                text-align: center;
                float: left;
                line-height: 80px;

                margin-right: 20px;
            }
            p {
                margin-right: 10px;
                margin-left: 100px;
                margin-top: 20px;
                margin-bottom: 50px;
                color: black;
            }
        }
    }

    .description {
        background-color: #589d72;
        width: 100%;
        padding-left: 300px;
        padding-right: 300px;
        padding-top: 90px;
        padding-bottom: 90px;

        p {
            color: #fff;
            text-align: center;
            font-size: 30px;
        }
    }

    .functionalities {
        background-color: #fff;
        width: 100%;
        padding-left: 50px;

        .heading {
            color: #589d72;
            font-size: 40px;
            padding-bottom: 30px;
            padding-top: 30px;
            text-align: left;
            padding-left: 10px;
            h3 {
                color: #589d72;
            }
        }
        .imageContainer {
            width: 100%;
            padding-bottom: 100px;
            text-align: center;
            .image {
                width: 100%;
                margin-top: -30px;
            }
            .text {
                text-align: left;
                padding-left: 50px;
                color: #000000be;
                font-size: 30px;
            }
            .icon {
                margin-right: 15px;
                font-size: 35px;
                color: #589d72;
            }
        }
    }

    .clients {
        width: 100%;
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 90px;
        padding-bottom: 90px;
        text-align: center;

        background-color: #f5f5f5;

        h2 {
            color: #548064;
            font-size: 40px;
        }
        p {
            font-size: 30px;
        }
    }

    .contact {
        width: 100%;
        padding-left: 300px;
        padding-right: 300px;
        padding-top: 70px;
        padding-bottom: 70px;
        text-align: center;
        color: #548064;
        background-color: #fff;

        h2 {
            color: black;
            font-size: 40px;
        }
        p {
            font-size: 30px;
        }
        .buttons {
            width: 25%;
            height: 15%;
            margin-top: 48px;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .ads {
        padding-top: 140px;
        padding-bottom: 24px;

        img {
            width: 160px;
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff !important;
        padding-left: 30px;
        padding-right: 30px;
        color: rgba(139, 141, 151, 0.5);
        .links {
            .link {
                color: rgba(139, 141, 151, 0.5);
            }
        }
        .copyright {
            display: flex;
            justify-content: center;
            margin-top: 8px;

            a {
                color: rgba(139, 141, 151, 0.5);
            }
        }
    }
}

.mobile {
    .top {
        .header {
            .logo {
                margin: 10px 0px 10px -10px;
                height: 40px;
                width: 40px;
            }
        }
        .text {
            color: black;
            text-align: center;
            position: relative;
            font-size: 20px;
            line-height: 45px;
            font-weight: 500;
            padding-bottom: 200px;
            padding-left: 60px;
            padding-right: 60px;
            padding-top: 0px;
            h1 {
                color: #467c5a;
            }
        }
        .buttons {
            width: 50%;
            height: 15%;
            margin-top: 10px;
            font-size: 15px;
            font-weight: bold;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 60px;
            align-items: center;
        }
    }

    .benefits {
        padding-left: 20px;
        padding-right: 20px;
        .heading {
            font-size: 30px;
            text-align: center;
        }
        .imageContainer {
            width: 50%;
            padding-bottom: 50px;
            text-align: center;
            .image {
                width: 90%;
                border-radius: 75px;
            }
        }
        .text {
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;
            color: #467c5a;
            font-size: 20px;
            p {
                margin-right: 0px;
                margin-left: 0px;
                margin-top: 20px;
                margin-bottom: 50px;
                color: black;
            }
        }
    }
    .description {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 80px;
        padding-bottom: 80px;

        p {
            color: #fff;
            text-align: center;
            font-size: 25px;
        }
    }
    .functionalities {
        width: 100%;
        padding-left: 25px;
        padding-right: 25px;

        .heading {
            padding-left: 0px;
            text-align: center;
            padding-bottom: 0px;
            padding-top: 10px;
            font-size: 25px;
        }
        .imageContainer {
            width: 80%;
            padding-bottom: 50px;
            text-align: center;

            .image {
                width: 100%;
                margin-top: -30px;
            }
            .text {
                font-size: 25px;
                padding-left: 0px;
                text-align: center;
                margin-left: 0px;
                line-height: 32px;
            }
        }
    }
    .clients {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 60px;
        padding-bottom: 60px;
        text-align: center;

        background-color: #f5f5f5;

        h2 {
            color: #548064;
            font-size: 30px;
        }
        p {
            font-size: 20px;
        }
        .image {
            width: 110%;
        }
    }

    .contact {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 60px;

        h2 {
            color: black;
            font-size: 30px;
        }
        p {
            font-size: 20px;
        }
        .buttons {
            width: 30%;
            height: 15%;
            margin-top: 48px;
            font-size: 20px;
            font-weight: bold;
        }
        .icon {
            font-size: 30px;
        }
    }

    .ads {
        padding-top: 70px;
        padding-bottom: 24px;

        img {
            width: 120px;
        }
    }
}

.mobileFooter {
    overflow-x: hidden;
    color: white;
    padding: 24px 0;
    justify-content: center;
    background-color: white;
    line-height: 8px;
    font-size: 10px;

    .links {
        display: flex;
        justify-content: center;
        flex-grow: 1;

        .link {
            color: rgba(139, 141, 151, 0.5);
        }
    }

    .copyright {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        margin-top: 8px;

        a {
            color: rgba(139, 141, 151, 0.5);
        }
    }
}
