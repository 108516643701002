.inputNumber {
    width: 100%;
}
.date {
    width: 100%;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}
