.container {
    background: #f5f5f5 !important;
    margin: -24px;

    .card {
        height: 100%;
        padding: 0;
    }

    .panelMobile {
        margin-bottom: 20px;
    }
}
