.disabled {
    td {
        a {
            color: #999;
        }
    }
}

.pheromone {
    color: red;
}

.paper {
    color: red;
}
