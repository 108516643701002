.container {
    &.high {
        color: red;
    }
    &.medium {
        color: orange;
    }
    &.low {
        color: green;
    }
    &.unknown {
        color: #000;
    }

    .label {
        margin-left: 6px;
    }
}   