.form {
    background-color: #fff;
    width: 100%;

    &.desktop {
        padding: 50px 150px;
    }

    &.mobile {
        padding: 50px 20px 0 20px;
    }

    .panel {
        display: flex;
        justify-content: center;

        .logo {
            height: 102px;

            path {
                fill: #548064;
            }
        }

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            margin-top: 30px;
            margin-bottom: 8px;
        }
        .createAcc {
            margin-bottom: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #8b8d97;
        }
        .haveAccountText {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #8b8d97;
        }
    }

    .button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        .signupButton {
            margin-top: 60px;
            height: 50px;
            background: 'primary';
            border-radius: 12px;
        }
    }

    .login {
        color: #000;
        margin-left: 6px;
    }

    .getStarted {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: rgba(91, 110, 198, 1);
    }

    .icon {
        font-size: 20px !important;
        color: transparent !important;
        margin-right: 8px;
    }

    .defaultIcon {
        font-size: 20px !important;
        color: rgba(110, 112, 121, 1);
        margin-right: 8px;
    }

    .panelSpace {
        margin-bottom: 30px;
    }
}
