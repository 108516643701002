.container {

    .map {
        border-radius: 12px;
    }
   
    .spin {
        position: absolute;
        margin-top: 280px;
        z-index: 100000000000000;
        left: 50%;
        transform: translateX(-50%);
    }

    .state {
        margin-top: 8px;
        font-weight: 700;
    }

    .link {
        display: block;
        margin-top: 16px;
        color: #000;
        font-weight: bold;
        text-decoration: underline;
    }  

}
         
