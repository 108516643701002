@import '~leaflet/dist/leaflet.css';

/*** Fonts ***/

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url('./resources/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url('./resources/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Light';
  src: local('Inter-Light'), url('./resources/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url('./resources/fonts/Inter-Medium.ttf') format('truetype');
}

/*** Default Styles ***/

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Inter-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*** Custom styles ***/

#root {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-background-clip: text;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.88);
}

input:-webkit-autofill{
  -webkit-text-fill-color: #000 !important;
}

/*** Crud pages buttons ***/

.buttons {
  margin-top: 30px;
  text-align: left;
  margin-bottom: 30px;
}

.table-buttons {
  display: flex;
  justify-content: flex-end;
  padding-left: 30px;
  font-size: smaller;
  border: none;
  background:transparent;
}

.table {
  overflow-x: auto;
}