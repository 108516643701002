.container {
    margin-bottom: 15px;
    margin-top: 20px;

    .spin {
        position: absolute;
        margin-top: 280px;
        z-index: 100000000000000;
        left: 50%;
        transform: translateX(-50%);
    }

    .popup {
        .country {
            margin-top: 8px;
            margin-bottom: 8px;
            font-size: 14px;
        }
        .level {
            margin-top: 0;
            margin-bottom: 0;
            opacity: 0.75;

            &:last-child {
                margin-bottom: 24px;
            }
        }
    }
    .card {
        height: 100%;
        padding: 0;
    }
}