.buttons {
    display: flex;
    justify-content: center;
    margin-top: 48px;
}

.link {
    color: #397350;
    text-decoration: underline;
}

.space {
    margin-left: 40px;
}

.avatar {
    vertical-align: 'middle';

    &.high {
        background-color: #ff4d4f;
    }

    &.medium {
        background-color: #ffc458;
    }
}
