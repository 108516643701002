.container {
    .top {
        background-color: #fff;
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        padding: 50px 150px;
    }
    .description {
        background-color: #fff;
        padding-right: 60px;
        padding-left: 60px;
        font-size: 30px;
        padding-bottom: 150px;
        align-items: left;
        h2 {
            color: #548064;
            padding-bottom: 20px;
            padding-top: 50px;
        }
        .icon {
            padding-right: 15px;
            color: #548064;
        }
    }
    .imageContainer {
        width: 100%;
        padding-top: 100px;
        padding-right: 40px;
        text-align: center;
        .image {
            width: 150%;
            padding-right: 30px;
        }
    }
    &.mobile {
        .top {
            padding: 30px 20px 0 20px;
        }
        .description {
            background-color: #fff;
            width: 100%;
            padding-right: 50px;
            padding-left: 50px;
            font-size: 20px;
            padding-bottom: 40px;
            text-align: center;

            h2 {
                color: #548064;
                padding-bottom: 20px;
                padding-top: 50px;
            }
            .icon {
                padding-right: 7px;
                color: #548064;
            }
        }
        .imageContainer {
            width: 50%;
            padding-top: 0px;
            padding-right: 0px;
            text-align: center;

            .image {
                width: 90%;
                padding-right: 0px;
            }
        }
    }
}
