.form {
    background-color: #fff;
    width: 100%;

    &.desktop {
        padding: 50px 150px;
    }

    &.mobile {
        padding: 50px 20px 0 20px;
    }

    .panel {
        display: flex;
        justify-content: center;
        img {
            height: 52px;
        }
        .createAcc {
            margin-bottom: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #8b8d97;
        }
        .haveAccountText {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #8b8d97;
        }
    }

    .button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        .signupButton {
            margin-top: 60px;
            height: 50px;
            background: #9747ff;
            border-radius: 12px;
        }
    }

    .icon {
        font-size: 20px !important;
        color: transparent !important;
        margin-right: 8px;
    }
}
