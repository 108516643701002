.layout {
    min-height: 100vh;

    .sider {
        .title {
            line-height: 60px;
            height: 60px;
            padding-left: 10px;
            white-space: nowrap;
            display: flex;
            justify-content: flex-start;
            align-content: center;

            .logo {
                margin: 10px 5px 10px 10px;
                height: 40px;
                width: 40px;
                fill: #548064;
            }

            span {
                font-size: 20px;
                font-weight: 700;
                line-height: 60px;

                color: #548064;
            }
        }

        &.collapsed {
            margin-right: 0;
        }

        .menu {
            border-inline-end: 0 none !important;
            padding: 0 10px;
            margin-top: 12px;

            li {
                height: 50px;
                line-height: 50px;
                margin-bottom: 8px;

                span {
                    vertical-align: middle !important;
                }
            }
        }
    }

    .header {
        background: #fff;
        padding: 0 0 0 16px;
        display: flex;
        align-items: center;
        height: 60px;

        .title {
            flex: 1;
            font-size: 20px;

            font-weight: 500;
            color: #45464e;
        }

        .breadcrumb {
            flex: 1;
        }

        .avatar {
            justify-content: center;
            background-color: #397350;
            color: #fff;
            cursor: pointer;
        }

        .notifications {
            margin-right: 12px;
            svg {
                font-size: 18px;
                color: #53545c;
            }
        }
    }

    .content {
        background: #f5f5f5;

        .main {
            background: #fff;
            padding: 24px;
            margin: 0 16px;
            margin-top: 16px;
            border-radius: 12px;
        }
    }

    .footer {
        padding-left: 20px;
        padding-right: 20px;
        color: rgba(139, 141, 151, 0.5);
        .links {
            flex-grow: 1;
            .link {
                color: rgba(139, 141, 151, 0.5);
            }
        }
        .copyright {
            display: flex;
            justify-content: flex-end;
            a {
                color: rgba(139, 141, 151, 0.5);
            }
        }
    }
}

.mobileLayout {
    overflow-x: hidden;
    min-height: 100vh;

    .header {
        background: #fff;
        padding: 0 16px 0 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;

        .headerLeft {
            display: flex;
            justify-content: flex-start;
            align-content: center;

            .title {
                line-height: 60px;
                height: 60px;
                white-space: nowrap;
                display: flex;
                justify-content: flex-start;
                align-content: center;

                .logo {
                    margin: 10px 5px 10px 10px;
                    height: 40px;
                    width: 40px;
                    fill: #548064;
                }

                span {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 60px;

                    color: #548064;
                }
            }
        }

        .headerRight {
            display: flex;
            align-items: center;
            .notifications {
                margin-right: 12px;
                svg {
                    font-size: 18px;
                    color: #53545c;
                }
            }
        }
    }

    .content {
        height: 100%;
        display: flex;

        .main {
            background: #fff;
            padding: 24px;
            min-height: 500px;
            margin: 0 16px;
            margin-top: 16px;
            border-radius: 12px;
            overflow: auto;
            flex: 1;

            &.multipanel {
                overflow: hidden;
            }
        }
    }
    .footer {
        padding: 24px 0;
        justify-content: center;
        color: rgba(139, 141, 151, 0.5);
        background-color: transparent;
        line-height: 8px;
        font-size: 10px;

        .links {
            display: flex;
            justify-content: center;
            flex-grow: 1;
            .link {
                color: rgba(139, 141, 151, 0.5);
            }
        }
        .copyright {
            display: flex;
            justify-content: center;
            flex-grow: 1;
            margin-top: 8px;
            a {
                color: rgba(139, 141, 151, 0.5);
            }
        }
    }
}

.topDrawer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        white-space: nowrap;
        display: flex;
        justify-content: flex-start;
        align-content: center;

        .logo {
            margin: 0 5px 0 0;
            height: 40px;
            width: 40px;
            fill: #548064;
        }

        span {
            font-size: 20px;
            font-weight: 700;
            line-height: 40px;
            color: #548064;
        }
    }
}
